import type { MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => {
  return [{ title: 'Lorikeet chat' }]
}

export default function Index() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center py-2">
      <h1 className="font-bold font-heading-h2">No page here</h1>
      <p className="font-text-m">
        Nothing to be found here. If you want to get in touch with The Lorikeet
        team, you can find us at{' '}
        <a href="mailto:support@lorikeetcx.ai">support@lorikeetcx.ai</a>.
      </p>
    </div>
  )
}
